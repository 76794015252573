<template>
  <quick-start />
</template>

<script>
const QuickStart = () => import('@/components/Car/QuickStart')

export default {
  name: 'QuickStartView',
  components: {
    QuickStart
  }
}
</script>
